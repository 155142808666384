<template>
  <div class="widgetContainer widgetContainer--scrollable businessDetail">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span
          class="icon-Arrow-big-left cancel"
          @click="drawerBack" />
        <p class="title">
          {{ getSelectedBusiness.legalName }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <p class="header-2">
        Business Profile
      </p>
      <div class="listWithInfo border-1">
        <ul>
          <li>
            <p class="label">
              {{ $t('profile_legalname') }}
            </p>
            <p class="value">
              {{ getSelectedBusiness.legalName }}
            </p>
          </li>
          <li>
            <p class="label">
              {{ $t('profile_dba') }}
            </p>
            <p class="value">
              {{ getSelectedBusiness.dba }}
            </p>
          </li>
        </ul>
      </div>

      <p class="wise-form__header">
        {{ $t('profile_account_header') }}
      </p>
      <div v-if="accounts.length" class="listWithInfov2 border-1">
        <ul>
          <li v-for="a in accounts" :key="a.id">
            <p class="label">
              {{ a.label }}
            </p>
            <p class="value">
              {{ a.accountNumber }}
            </p>
          </li>
        </ul>
      </div>

      <div class="listWithActionsv2">
        <ul>
          <li @click="addAccount(getSelectedBusiness)">
            <div class="details">
              <p class="label">
                Add Account
              </p>
            </div>
            <span class="icon-Arrow-small-right icon" />
          </li>
        </ul>
      </div>

      <div class="listWithInfo">
        <p class="header-2">
          Owners
        </p>
        <ul>
          <li v-for="owner in getSelectedBusiness.owners" :key="owner.id">
            <p class="label">
              {{ `${owner.person.firstName} ${owner.person.lastName}` }}
            </p>
            <p class="value">
              {{ `${owner.ownership}%` }}
            </p>
          </li>
        </ul>
      </div>
      <div class="listWithActionsv2">
        <ul>
          <li @click="addOwner">
            <div class="details">
              <p class="label">
                Add Business Owner
              </p>
            </div>
            <span class="icon-Arrow-small-right icon" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {gmapApi} from 'vue2-google-maps';
import {getBrandConfig} from '@/utils/env';
import {accountService, memberService} from '@/services/solidfi';

export default {
  data() {
    return {
      lat: null,
      lng: null,
      accounts: []
    };
  },
  computed: {
    ...mapGetters('dashboard', ['getSelectedBusiness']),
    google: gmapApi,
    address() {
      const user = this.getSelectedBusiness;
      const address = user.address;
      if (address.line2 && address.line2.length > 0) {
        return (
          address.line1 +
            ', ' +
            address.line2 +
            ', ' +
            address.city +
            ', ' +
            address.state
        );
      }
      return address.line1 + ', ' + address.city + ', ' + address.state;
    }
  },
  created() {
    this.getAccountsList();
    this.getOwners();
    this.getLatLong();
  },
  methods: {
    ...mapActions('account', ['listAllAccounts']),
    getLatLong() {
      this.$gmapApiPromiseLazy().then(() => {
        if (this.google) {
          const geocoder = new this.google.maps.Geocoder();
          const address = this.getSelectedBusiness.address;
          const addressString =
              address.line1 +
              ',' +
              address.line2 +
              ',' +
              address.city +
              ',' +
              address.country;

          geocoder.geocode({address: addressString}, (results, status) => {
            if (status == this.google.maps.GeocoderStatus.OK) {
              const latitude = results[0].geometry.location.lat();
              const longitude = results[0].geometry.location.lng();
              this.lat = latitude;
              this.lng = longitude;
            }
          });
        }
      });
    },
    addOwner() {
      this.$router.push({name: 'OwnersList'});
    },
    addAccount(business) {
      if (business.kyb && business.kyb.status === 'approved') {
        if (!this.isHellosignEnabled || business.disclosureStatus === 'completed' || ['soleProprietor', 'singleMemberLLC'].includes(
          business.entityType
        ) ) {
          let businessId = business.id;
          this.$store.commit('account/updateCreateAccountData',{
            businessId,
            type: 'businessChecking'
          });
          setTimeout(() => {
            this.drawerPush('SetupAccount');
          }, 0);
        } else {
          this.$router.push('/kyb/businessStatus');
        }
      } else {
        if (business.kyb.status === 'notStarted') {
          this.addBusiness();
        } else {
          this.$router.push('/kyb/businessStatus');
        }
      }
    },
    helpCenter() {
      const url = getBrandConfig('helpCenter', 'https://intercom.help/solid-2cf7e39d14f4/en/');
      var win = window.open(
        url,
        '_blank'
      );
      win.focus();
    },
    getOwners(){
      const loader = this.showLoader();
      let business = {...this.getSelectedBusiness};
      memberService.all(business.id)
        .then(res => {
          business.owners = res.data.data
          this.$store.commit('dashboard/setSelectedBusiness',{...business});
        }).catch(e => {
          console.log(e);
          loader.close()
        })
    },
    getAccountsList() {
      const loader = this.showLoader();
      accountService.getAll(100,this.getSelectedBusiness.id)
        .then(res => {
          this.accounts = res.data.data
        })
        .catch()
        .finally(() => {
          loader.close()
        })
    },
    openDisclosures() {
      const url = getBrandConfig('disclosures', 'https://intercom.help/solid-2cf7e39d14f4/en/collections/2921684-legal-disclosures-terms-policies')
      var win = window.open(
        url,
        '_blank'
      );
      win.focus();
    },
    editAddress() {
      this.drawerPush('EditBusinessAddress');
    },
  }
};
</script>
<style lang="scss">
.businessDetail {
  .loginHeader {
    .submit {
      margin-left: auto;
    }
  }
}
</style>
